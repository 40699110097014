<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Update Period For Add Business Account Notification</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <v-form ref="add_business_form" id="kt_settings_add_business_form" v-model="valid" lazy-validation>

            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-select
                      :items="period_list"
                      v-model="selected_period_add_business_account_notification"
                      label="Select Period"
                      outlined
                      :rules="[v => !!v || 'This field is required']"
                      dense
                      class="pa-2"
                      id="period_add_business_account_notification"
                ></v-select>
              </v-col>  
              <v-col cols="12" sm="6">
                  <v-text-field
                    label="Date"
                    v-model="selected_period_add_business_account_notification_date"
                    :rules="dateRule"
                    type="date"
                    outlined
                    dense
                    class="pa-2"
                    id="period_add_business_account_notification_date"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-container fluid class="card-footer pt-3">
              <v-btn
                :disabled="!valid"
                color="success"
                id="add_business_submit"
                class="mr-4"
                @click="validate"
              >
              <b-spinner v-if="disable_btn1" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-container>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
    <div class="card card-custom gutter-b" v-if="company_type == 'Small Enterprise'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Update Period For Complete Training Notification</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <v-form ref="add_training_form" id="kt_settings_add_training_form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="6">
                <v-select
                      :items="period_list"
                      v-model="selected_period_complete_training_notification"
                      label="Select Period"
                      outlined
                      :rules="[v => !!v || 'This field is required']"
                      dense
                      class="pa-2"
                      id="period_complete_training_notification"
                ></v-select>
              </v-col>  
              <v-col cols="12" sm="6">
                  <v-text-field
                    label="Date"
                    v-model="selected_period_complete_training_notification_date"
                    :rules="dateRule"
                    type="date"
                    outlined
                    dense
                    class="pa-2"
                    id="period_complete_training_notification_date"
                  ></v-text-field>
              </v-col>
            </v-row>

            <v-container fluid class="card-footer pt-3">
              <v-btn
                :disabled="!valid"
                color="success"
                id="add_training_submit"
                class="mr-4"
                @click="validate_training"
              >
              <b-spinner v-if="disable_btn2" label="Spinning"></b-spinner>
                Update
              </v-btn>
            </v-container>
          </v-form>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT } from "@/core/services/store/committee.module";
import { UPDATE_SETTINGS_TRAINING } from "@/core/services/store/committee.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      company_type: "Small Enterprise",
      period_list: [
                      "Daily", "Weekly",
                      "Fortnightly", "Monthly", 
                      "Quarterly", "Half Yearly",
                      "Yearly"
                    ],
      selected_period_add_business_account_notification:"",
      selected_period_add_business_account_notification_date:"",
      selected_period_complete_training_notification:"",
      selected_period_complete_training_notification_date:"",
      valid: true,
      disable_btn1: false,
      disable_btn2: false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig","getCompany"]),
  },
  watch: {
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
  },
  mounted() {
    this.company_type = this.getCompany.company_type;
    let context = this;
    context.getSettings();
    // set the tab from previous
    context.$store.dispatch(SET_BREADCRUMB, [{ title: "Settings" }]);

    const add_business_form = KTUtil.getById("kt_settings_add_business_form");
    const add_training_form = KTUtil.getById("kt_settings_add_training_form");

    context.fv = formValidation(add_business_form, {});
    EventBus.$on("UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Settings updated successfully.", "success");
      } else {
        Swal.fire("Error", "Error in updating settings.", "error");
      }
    });

    context.fv = formValidation(add_training_form, {});
    EventBus.$on("UPDATE_SETTINGS_TRAINING", function(payLoad) {
      if (payLoad) {
        Swal.fire("Success", "Settings updated successfully.", "success");
      } else {
        Swal.fire("Error", "Error in updating settings.", "error");
      }
    });

  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getSettings() {
      let context = this;
      axios({
        method: "get",
        url: "settings",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
      function(result) {
        context.selected_period_add_business_account_notification = result.data.settings.period_add_business_account_notification;
        context.selected_period_add_business_account_notification_date = result.data.settings.period_add_business_account_notification_date;
        context.selected_period_complete_training_notification = result.data.settings.period_complete_training_notification;
        context.selected_period_complete_training_notification_date = result.data.settings.period_complete_training_notification_date;
      }, 
      function() {
        Swal.fire(
          "Error",
          "Error in fetching Settings",
          "info"
        );
      }
    );
    },
    updateSettingsBusinessAccount() {
      let context = this;
      context.valid = false;
       
      this.$store.dispatch(UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT, {
        period_add_business_account_notification : context.selected_period_add_business_account_notification,
        period_add_business_account_notification_date : context.selected_period_add_business_account_notification_date
      }).then(function(result) {
          context.disable_btn1 = false;
      });
      context.valid = true;
    },
    updateSettingsTraining() {
      let context = this;
      context.valid = false;
       
      this.$store.dispatch(UPDATE_SETTINGS_TRAINING, {
        period_complete_training_notification : context.selected_period_complete_training_notification,
        period_complete_training_notification_date : context.selected_period_complete_training_notification_date
      }).then(function(result) {
          context.disable_btn2 = false;
      });
      context.valid = true;
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.add_business_form.validate()) {
          context.disable_btn1 = true;
          this.updateSettingsBusinessAccount(); 
      } else {
        this.snackbar = true;
      }
    },
    validate_training() {
      let context = this;
          this.fv.on("core.form.invalid", () => {
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        });
        if (this.$refs.add_training_form.validate()) {
            context.disable_btn2 = true;
            this.updateSettingsTraining(); 
        } else {
          this.snackbar = true;
        }
    }
  }
};
</script>
